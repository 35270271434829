import React from "react";
import Layout from "../components/layout"
import * as styles from './styles/main.module.scss'
import * as coorperationStyle from '../components/cooperation/cooperation.module.scss'
import { changeColor } from "../source/changeColor"
import Cooperation from "../components/cooperation/cooperation";
import {contactImg,dateImg,podpisImg} from '../images/image'
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const cooperation = () => {
    
    return( 
    <>
        <Helmet>
         <meta name='description' content='Jesteśmy otwarci na współpracę z firmą z każdej branży, gwarantujemy dostęp do zespołu specjalistów, sprawną i terminową realizację powierzonych zadań.'/>
      </Helmet>
        <Layout pageTitle="Wspolpraca">
         <div className={styles.firstSection}>
            <div className={`${styles.container}`}>
                <div className={`${styles.sectionTitle}`} data-sal="slide-up" data-sal="100"> 
                    <h1>{changeColor('Współpraca z nami jeszcze prostsza',2)}</h1>
                    <p>Współpraca z Nami to gwarancja dostępu do zespołu specjalistów, sprawna i terminowa realizacja powierzonych zadań. </p>
                </div>

                <div className={coorperationStyle.cooperation}>
                    <Link to='/kontakt'>
                        <Cooperation dataSal="slide-fade" dataSalDelay="200" imageName={contactImg} nameBlock='Kontakt z biurem' />
                    </Link>
                        <Cooperation dataSal="slide-fade" dataSalDelay="200" imageName={dateImg}  nameBlock='Wyznaczenie daty spotkania' />
                        <Cooperation dataSal="slide-fade" dataSalDelay="200" imageName={podpisImg} nameBlock='Podpisanie umowy' />
                        <Cooperation  dataSal="slide-fade" dataSalDelay="200" oneText='Sporządzimy za Ciebie wszystkie niezbędne dokumenty do US i ZUS' />
                        <Cooperation  dataSal="slide-fade" dataSalDelay="200" oneText='Co miesiac dostaraczasz nam dokumenty za poszczególne okresy obrachunkowe' />
                        <Cooperation  dataSal="slide-fade" dataSalDelay="200" oneText='Informujemy Cię o wyniku finansowym za zamknięty okres obrachunkowy' />
                        
                </div>
            </div>

        </div>
    </Layout>
    </>
    )
}

export default cooperation;