import React from 'react'
import * as coorperationStyle from './cooperation.module.scss'

const Cooperation = ({dataSal,dataSalDelay,imageName,nameBlock,description,oneText,event}) => {
    return(
        <div className={coorperationStyle.section}>
                            <div className={`${coorperationStyle.directoryRound} ${oneText?coorperationStyle.oneText:null}`}></div>
                            {!oneText?
                            
                            <div className={coorperationStyle.block} data-sal={dataSal} data-delay={dataSalDelay}> 
                                <img src={imageName} alt={nameBlock}/>
                                <h2>{nameBlock}</h2>
                                <p>{description}</p>
                            </div>
                            :
                            <div className={coorperationStyle.block} data-sal={dataSal} data-delay={dataSalDelay}>
                                <h3>{oneText}</h3>
                            </div>
                            }
        </div>
    )
}

export default Cooperation;